// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".C7AjnU5N87-df8ycipvIG{display:flex;flex-direction:row;align-items:center;justify-content:flex-end;width:100%;column-gap:10px;margin-right:10px}", "",{"version":3,"sources":["webpack://./src/components/Login.module.scss"],"names":[],"mappings":"AAAA,uBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,wBAAA,CACA,UAAA,CACA,eAAA,CACA,iBAAA","sourcesContent":[".Login {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n  width: 100%;\n  column-gap: 10px;\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Login": "C7AjnU5N87-df8ycipvIG"
};
export default ___CSS_LOADER_EXPORT___;
